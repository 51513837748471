import * as React from "react"
import { Link } from "gatsby"
import { navigate } from "@reach/router"

const NotFoundPage = () => {
  React.useEffect(() => {
    navigate('/your-redirect/');
  }, []);
  return null;
  return (
    <main>

    </main>
  )
}

export default NotFoundPage
